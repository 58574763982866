// index.php
// scssは読み込み順に記述
import 'normalize.css';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import '../scss/index.scss';
import 'webp-in-css/polyfill';
import 'lazysizes';
// import jQuery from 'jquery'; // CDNで読み込み、生成されたvendor.jsは使わない
// import 'slick-carousel'; // splitChunksでslick.jsを生成。
import renderChart from './modules/chart/chart';
import Carousel from './modules/slick/carousel';
import moveToTop from './modules/to-top/move_top';
import toTarget from './modules/to-target/to_target';
import FAQToggle from './modules/faq/faq_toggle';
import SlideBottomBanner from './modules/slide_toggle/slide_banner';
// import Reviews from './modules/google_reviews/reviews';

jQuery((): void => {
  Carousel(jQuery('.hero'), true, true, 10000, 700, true, false); // トップカルーセル
  Carousel(jQuery('.pickup-items'), false, true, 3600, 700, true, false, 3, 1); // 買取強化アイテム
  FAQToggle();
});

document.addEventListener(
  'DOMContentLoaded',
  (): void => {
    renderChart();
    // Reviews();
    moveToTop();
    SlideBottomBanner();

    // const from: HTMLElement | null = document.getElementById('to-target');
    const from: NodeListOf<HTMLElement> | null = document.querySelectorAll('#to-target');
    const to: HTMLElement | null = document.getElementById('target');

    if (from !== null || to !== null) {
      toTarget(from, to);
    }
  },
  { passive: false }
);
