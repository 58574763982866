// slideUp
function slideUp(elm: HTMLElement, duration = 300, timing = 'linear'): void {
  console.log('scroll Up');

  // const height: number = elm.offsetHeight;
  // console.log( height );

  elm.style.bottom = '0';
  elm.style.transitionProperty = 'height, margin, padding, bottom';
  elm.style.transitionDuration = duration + 'ms';
  elm.style.transitionTimingFunction = timing;
  elm.style.paddingTop = '0';
  elm.style.paddingBottom = '0';
  elm.style.marginTop = '0';
  elm.style.marginBottom = '0';

  // 上で設定した値をduration秒後にクリアする
  setTimeout((): void => {
    elm.style.removeProperty('height');
    elm.style.removeProperty('padding-top');
    elm.style.removeProperty('padding-bottom');
    elm.style.removeProperty('margin-top');
    elm.style.removeProperty('margin-bottom');
    elm.style.removeProperty('transition-duration');
    elm.style.removeProperty('transition-property');
    elm.style.removeProperty('transition-timing-function');
  }, duration);
}

// slideDown
function slideDown(elm: HTMLElement, duration = 300, timing = 'linear'): void {
  console.log('scroll Down');

  const height: number = elm.offsetHeight;
  console.log(height);

  elm.style.bottom = -height + 'px';
  elm.style.paddingTop = '0';
  elm.style.paddingBottom = '0';
  elm.style.marginTop = '0';
  elm.style.marginBottom = '0';
  elm.style.transitionProperty = 'height, margin, padding, bottom';
  elm.style.transitionDuration = duration + 'ms';
  elm.style.transitionTimingFunction = timing;

  // 上で設定した値をduration秒後にクリアする
  setTimeout((): void => {
    elm.style.removeProperty('height');
    elm.style.removeProperty('overflow');
    elm.style.removeProperty('transition-duration');
    elm.style.removeProperty('transition-property');
    elm.style.removeProperty('transition-timing-function');
  }, duration);
}
export { slideUp, slideDown };
